import React from "react";

import HtmlHead from "../components/HtmlHead"
import SiteHeader from "../components/SiteHeader"
import SiteFooter from "../components/SiteFooter"

import { onNavOverlayClick } from "../scripts/navigation"


const placeImage = (props, position) => {
	if (props.position === position) {
		return (
			<figure className={"wrapper__image wrapper__image--"+props.position}>
				<img src={props.imgSrc}
					alt={`/${props.imgAlt}`} />
			</figure>
		)
	}
}

export default ({ children }) => (
	<>
		<div className="layout-container layout-container--aside-image">
			<HtmlHead
				title={children[1].seo.title}
				desc={children[1].seo.desc}
				canonicalURL={children[1].seo.canonicalURL}
				socialImage={children[1].seo.socialImage}
			/>

			<SiteHeader activeNav={"about"} />

			<section className="page-content" role="main">
				<div className="wrapper">
					{placeImage(children[1], 'left')}
					
					<div className="wrapper__content">
						{ children[0] }
					</div>

					{placeImage(children[1], 'right')}
				</div>
			</section>

			<SiteFooter />
		</div>

		<div className="nav-overlay" onClick={onNavOverlayClick} />
	</>
)
