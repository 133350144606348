import React from "react"
import Layout from "../layouts/aside-image"


const layoutProps = {
	position: "left",
	imgSrc: "/src/images/carmen-wu-graphic-designer.jpg",
	imgAlt: "Carmen Wu, graphic designer, smiling brightly",
	seo: {
		title: "About + Contact",
		desc: "Hi there! I am Carmen Wu, a graphic designer from Toronto. Let's get in touch and learn how I can help you with your graphic design needs.",
		canonicalURL: "about",
		socialImage: "src/images/carmen-wu-graphic-designer.jpg"
	}
}

export default (children, props) => (
	<Layout>
		<>
		<h1 className="sr-only">About + Contact</h1>

		<article>
			<h2 className="heading-2">It's Your Girl!</h2>

			<p>HI! My name is Carmen Wu, born in Hong Kong, raised in Toronto. I am a self starter who loves design challenges and problem solving. I am analytical, yet creative with an acute attention to details. In my design, I strive for simplicity and clarity.</p>

			<p>When I am not designing, I like to watch anime, trying different types of food, playing video &amp; boardgames. To stay active, I play beach volleyball, and when weather permits, I go snowboarding.</p>
		</article>

		<article>
			<h2 className="heading-2">Let's Connect</h2>

			<p>Need to get in touch? Please don't hesitate to email me at <a href="mailto:design@carmenwu.com?subject=I need a designer!">design@carmenwu.com</a>. I would love to hear from you!</p>
		</article>
		</>
		
		{layoutProps}
	</Layout>
)